export const isURLValid = (input) => {
  const urlRegex =
    /^[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/;

  return urlRegex.test(input);
};

export const isEmailValid = (input) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(input);
};

export const isPhonenoValid = (input) => {
  const phonenoRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}/;

  return phonenoRegex.test(input);
};

export const isFileNameValid = (input) => {
  const fileNameValid = /^.{0,30}$/;

  return fileNameValid.test(input);
};


export function trimObjectValues(obj) {
  const trimmedObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'string') {
        trimmedObj[key] = obj[key].trim();
      } else {
        trimmedObj[key] = obj[key];
      }
    }
  }

  return trimmedObj;
}

export const getErrorMessage = (error) => {
  console.log(error);
  return error?.data?.message || error?.statusText || 'Something went wrong!';
};


export function getFileNameAndType(fileNameString) {
  const parts = fileNameString.split(".");
  
  const baseName = parts.length > 1 ? parts.slice(0, -1).join(".") : parts[0];
  
  const fileType = "video/mp4";

  return { fileName: baseName, fileType };
}