import { Stack } from "@mui/material";
import DownloadBox from "../DownloadBox";
import { useContext, useEffect, useState } from "react";
import { getDataFromLocalStorage } from "utils/videoUtils";
import { Paper, Typography } from "@material-ui/core";
import { GlobalContext } from "context";
import moment from "moment";
 
const GROUP = () => {
  const video_group = getDataFromLocalStorage("video_group") || [];
  const groupedData = video_group?.reduce((acc, item) => {
    const groupName = item.group_name;
    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(item);
    return acc;
  }, {});
  return groupedData;
};

const getCompletedCount = (list) => {
  let total_video = list?.length || 0;
  let downloaded_video = 0;
  let group_name = list?.[0]?.group_name || "";

  const timestamp = parseInt(group_name?.split("_")[1]);
  const group_created_date = moment(timestamp).format("DD MMM YYYY");
  const group_created_time = moment(timestamp).format("h:mm a");

  list?.forEach((item) => {
    if (item?.completed) {
      downloaded_video += 1;
    }
  });
  return {
    total_video,
    downloaded_video,
    group_created_date,
    group_created_time,
    all_data: list,
  };
};

const DownloadSection = () => {
  const {
    state: { showLoader, download = {} },
  } = useContext(GlobalContext);

  const { type } = download;

  const [downloadBoxData, setDownloadBoxData] = useState({});

  useEffect(() => {
    const grouped = GROUP();
    setDownloadBoxData(grouped);
  }, []);

  useEffect(() => {
    if (type === "DOWNLOAD_COMPLETE") {
      const grouped = GROUP();
      setDownloadBoxData(grouped);
    }
  }, [type]);

  if (!Object.keys(downloadBoxData)?.length && !showLoader) {
    return (
      <>
        <div>
          {true && (
            <Typography variant="h4" className="mt-5 text-center">
              No Data Found!
            </Typography>
          )}
        </div>
      </>
    );
  }

  return (
    <Paper variant="outlined" style={{ borderRadius: ".5rem", border: "none" }}>
      <Stack
        direction={"column"}
        gap={1}
        maxHeight={"250px"}
        my={2}
        sx={{ overflowY: "scroll" }}
      >
        {Object.values(downloadBoxData)?.map((item) => (
          <DownloadBox data={getCompletedCount(item)} />
        ))}
      </Stack>
    </Paper>
  );
};

export default DownloadSection;
