export const sampleComments = [
  {
    time: "00:01:23",
    text: "This is a sample comment at 01:23 seconds.",
  },
  {
    time: "00:02:45",
    text: "Another sample comment at 02:45 seconds.",
  },
  {
    time: "00:03:56",
    text: "Yet another sample comment at 03:56 seconds.",
  },
];

export const sliderStyles = {
  paddingBottom: 0,
  "& .MuiSlider-mark": {
    backgroundColor: "yellow",
    height: 8,
    width: 8,
    borderRadius: "50%",
  },
  "& .MuiSlider-markLabel": {
    color: "white",
    fontSize: "0.75rem",
    maxWidth: "250px",
    textAlign: "center",
    whiteSpace: "break-spaces",
    textWrap: "wrap",
    top: "-2rem",

    backgroundColor: "#3c3c3c",
    padding: "2px 4px",
  },
};

export const formatBytes = (bytes) => {
  if (isNaN(bytes) || bytes < 0) return false;
  if (bytes === 0) return "0 B";
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const getDataFromLocalStorage = (key) => {
  try {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    } else {
      return false;
    }
  } catch (error) {
    console.error(
      `Error retrieving or parsing ${key} from localStorage:`,
      error
    );
    return false;
  }
};

export const convertImageToBase64WithMetadata = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      const contentType = file.type.split("/")[1];

      const formattedString = {
        base64: base64String,
        filename: file?.name,
        content_type: contentType,
      };

      resolve(formattedString);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

export const getBase64Image = (url, setProfileImg) => {
  if (typeof url !== "string" || typeof setProfileImg !== "function") {
    console.error("Invalid arguments passed to getBase64Image.");
    return;
  }

  const img = new Image();

  img.crossOrigin = "anonymous";

  img.onload = () => {
    try {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        setProfileImg(dataURL);
      } else {
        console.error("Failed to get canvas context.");
      }
    } catch (error) {
      console.error("Error processing image to base64:", error);
    }
  };

  img.onerror = (error) => {
    console.error("Error loading image:", error);
  };

  img.src = url;

  setTimeout(() => {
    if (!img.complete) {
      console.warn("Image loading timed out or failed.");
    }
  }, 10000);
};

export const checkBoolean = (value) => {
  if (typeof value === "boolean") {
    return value;
  } else {
    console.error("The provided value is not a boolean.");
    return false;
  }
};


export const convertSecondsToHMS = (totalSeconds, readable = true) => {
  const numericSeconds = Number(totalSeconds);

  if (isNaN(numericSeconds) || numericSeconds < 0) {
    return null;
  }

  const hours = Math.floor(numericSeconds / 3600);
  const minutes = Math.floor((numericSeconds % 3600) / 60);
  const seconds = Math.floor(numericSeconds % 60);

  if (readable) {
    let readableTime = "";

    if (hours > 0) {
      readableTime += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
      readableTime += `${minutes} min `;
    }
    if (seconds > 0 || (hours === 0 && minutes === 0)) {
      readableTime += `${seconds} sec`;
    }

    return readableTime.trim();
  }

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  if (hours > 0) {
    const formattedHours = String(hours).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
};

