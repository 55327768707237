import React, { useContext, useState } from "react";
import { Box, TextField, Button, Snackbar } from "components";
import logo from "assets/images/login-logo.png";
import { GlobalContext, actions } from "context";
import { Link } from "react-router-dom";
import styles from "./login.module.scss";
import AuthServices from "api/services/auth-services";
import { isEmailValid } from "utils/validator";
import { Paper } from "@material-ui/core";
import { Stack } from "@mui/material";

const INVALID_EMAIL = "Invalid Email!";
const INVALID_PASSWORD = "Invalid Password";

const inputValidationMessage = {
  email: "Enter Email id",
  password: "Enter Password",
  invaild: "Invalid Credientials",
};

const Login = () => {
  const { dispatch } = useContext(GlobalContext);
  const [credientials, setCredientials] = useState({});
  const [validation, setValidation] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const inputFields = ["email", "password"];
  const [wrongEmail, setWrongEmail] = useState();
  const [wrongPassword, setWrongPassword] = useState();
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [passLenErr, setPasswordLenErr] = useState(false);

  const validate = () => {
    let invalidFields = [];
    inputFields.forEach((item) => {
      if (!credientials[item]) {
        invalidFields.push(item);
      }
    });
    if (invalidFields.length) {
      setValidation([...invalidFields]);
    } else {
      if (!invalidEmail && !passLenErr) {
        setValidation([]);
        logIn();
      }
    }
  };

  const logIn = async () => {
    storeHandler(actions.SHOW_LOADER, true);

    try {
      const { is_login, qa_status, access_token, refresh_token } =
        await AuthServices.login(credientials);
      localStorage.setItem("isAdmin", !qa_status);
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);

      storeHandler(actions.LOG_IN, {
        isLoggedIn: is_login,
        isAdmin: !qa_status,
      });
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
      const { data } = err || {};
      const { message } = data || {};
      if (message === INVALID_EMAIL) {
        setWrongEmail(INVALID_EMAIL);
      } else if (message === INVALID_PASSWORD) {
        setWrongPassword(INVALID_PASSWORD);
      } else {
        const snackdata = {
          message,
          class: "error",
        };
        setShowSnackBar(true);
        setSnackBarContent({ ...snackdata });
      }
    }
  };
  const storeHandler = (type, payload) => dispatch({ type, payload });

  const emailValidator = (event) => {
    const value = event.target.value;
    setInvalidEmail(!isEmailValid(value));
  };

  const loginInfo = (key, value) => {
    const invalidFields = [...validation];
    const index = invalidFields.indexOf(key);
    if (index > -1) {
      invalidFields.splice(index, 1);
      setValidation([...invalidFields]);
    }
    const data = { ...credientials, [key]: value };
    setCredientials({ ...data });
    setWrongEmail();
    setWrongPassword();
  };

  const validatePassword = () => {
    const { password } = credientials;
    setPasswordLenErr(!!password && password.length < 8);
  };

  return (
    <Paper
      variant="outlined"
      style={{
        padding: "1rem 3rem",
        borderRadius: ".7rem",
      }}
    >
      <Box textAlign="center" className="mt-4 mb-2">
        <img src={logo} alt="logo" className={styles.logo} />
      </Box>
      <form>
        <TextField
          placeholder="Email"
          variant="outlined"
          fullWidth
          className={"mb-3"}
          value={credientials?.email || ""}
          onChange={(event) =>
            loginInfo("email", event.target.value.toLowerCase())
          }
          onBlur={emailValidator}
          helperText={
            (validation.includes("email") && inputValidationMessage.email) ||
            (invalidEmail && "Enter valid email id") ||
            wrongEmail
          }
        />

        <TextField
          placeholder="Password"
          variant="outlined"
          fullWidth
          onBlur={validatePassword}
          passwordfield={true}
          onChange={(event) => loginInfo("password", event.target.value)}
          helperText={
            (validation.includes("password") &&
              inputValidationMessage.password) ||
            wrongPassword ||
            (passLenErr && "Enter min 8 character")
          }
        />

        <Box textAlign="center" className={"py-3"} fontWeight="bold">
          <Link style={{ color: "var(--error-light)" }} to="/forgot-password">
            Forgot Password ?
          </Link>
        </Box>

        <Stack justifyContent="center" direction="row">
          <Button
            variant="contained"
            color="primary"
            onClick={validate}
            style={{
              padding: ".4rem 1.5rem",
              background:
                "linear-gradient(100deg, var(--error-main) 0%, rgba(255,135,135,1) 55%, var(--error-main) 100%)",
            }}
          >
            Login
          </Button>
        </Stack>
      </form>
      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </Paper>
  );
};

export default Login;
