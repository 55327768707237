import React, { useContext, useEffect, useState } from "react";
import { Button, InputField } from "components";
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";
import styles from "./rename-popup.module.scss";
import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";
import { isFileNameValid } from "utils/validator";
import { VIDEO_INPUT_LIMITS } from "utils/constant";

const RenamePopup = ({
  open,
  onConfirm,
  id,
  filename,
  getFreelancerDetails,
  freelancerDetail,
}) => {
  const [name, setName] = useState(filename || "");
  const [nameEmpty, setNameEmpty] = useState(filename || "");
  const { dispatch } = useContext(GlobalContext);
  const [existingVideos, setExistingVideos] = useState([]);
  const [isNamePresent, setIsNamePresent] = useState(false);

  useEffect(() => {
    if (freelancerDetail?.video?.length) {
      let videoName = freelancerDetail?.video?.map((item) => item.name) || [];
      setExistingVideos(videoName);
    }
  }, []);

  const renameVideo = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const payload = {
        id,
        name,
      };
      await AuthServices.renameVideo(payload);
      getFreelancerDetails();
      onConfirm(null, null, true);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      onConfirm();
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const validate = () => {
    const isNameExists = existingVideos.includes(name);
    if (isNameExists) {
      setIsNamePresent(true);
    } else {
      if (name) {
        setNameEmpty(false);
        setIsNamePresent(false);
        renameVideo();
      } else if (!name) {
        setNameEmpty(true);
      }
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const handleClose = () => {
    onConfirm();
  };

  const updateName = (val) => {
    if (!isFileNameValid(val)) return;
    setName(val);
    isNamePresent && setIsNamePresent(false);
  };

  const handleClickInsideDialog = (event) => {
    event.stopPropagation();
    console.log("Clicked inside the dialog");
  };

  return (
    <Dialog
      open={open}
      className={styles.confirmDialog}
      maxWidth={"sm"}
      onClose={handleClose}
      onClick={handleClickInsideDialog}
    >
      <DialogContent>
        <DialogContentText className={styles.dialogContent}>
          <p className="mt-0 txt-bold txt-grey-drk">Rename File</p>
          <InputField
            variant="outlined"
            className={"mr-3"}
            fullWidth
            value={name || ""}
            onChange={(event) => {
              const video_name = event.target.value

              if (video_name.length <= VIDEO_INPUT_LIMITS.NAME) {
                updateName(video_name)
              }

            }}
            helperText={
              (isNamePresent && "Entered name already exists") ||
              (nameEmpty === true && "Video name is required.")
            }
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.footerSec}>
        <Button
          variant="contained"
          color="primary"
          className={`btn-lg txt-lg ${styles.renameBtn}`}
          onClick={(e) => {
            e.stopPropagation();
            validate();
          }}
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenamePopup;
