import { useState, useEffect, useContext, useRef } from "react";
import styles from "./freelancer.module.scss";
import { InputField, UserInfoCard } from "components";
import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";

import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";
import AddFreelancer from "./add-freelancer/add-freelancer";
import { Paper, Typography } from "@material-ui/core";
import ButtonWithIcon from "components/IconButton/icon-button";
import { Stack } from "@mui/material";
import { checkBoolean } from "utils/videoUtils";
import FreelancerSortPopover from "./freelancer-sort-popover/FreelancerSortPopover";
import FreelancerFilterPopover from "./freelancer-filter-opover/FreelancerFilterPopover";
import { getErrorMessage } from "utils/validator";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

function createQueryString(filterCriteria, searchQuery, sortCriteria) {
  const filterCriteriaString = filterCriteria
    .map((item) => {
      const filterType =
        item === "admin" || item === "qa" ? "filter_user" : "filter_status";
      return `${filterType}=${encodeURIComponent(item)}`;
    })
    .join("&");

  const searchQueryString = `search=${encodeURIComponent(searchQuery)}`;
  const sortQueryString = `sort_name=${encodeURIComponent(
    sortCriteria === "acc" ? "asc" : sortCriteria === "dec" ? "desc" : ""
  )}`;
  const limitString = `limit=${encodeURIComponent(15)}`;

  const queryParts = [
    searchQueryString,
    sortQueryString,
    limitString,
    filterCriteriaString,
  ];

  return queryParts.filter((part) => part).join("&");
}

const FreeLancer = () => {
  const {
    dispatch,
    state: { isAdmin, showLoader },
  } = useContext(GlobalContext);
  const IS_ADMIN = checkBoolean(isAdmin);

  const [open, setOpen] = useState(false);
  const [freeLancerDataCopy, setFreeLancerDataCopy] = useState([]);
  const [lek, setLek] = useState(undefined);
  const observerRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getFreelancerData = async (query) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data, lek } = await AuthServices.getFreelancer(query);
      setFreeLancerDataCopy((pre) => {
        return [...pre, ...data];
      });
      setLek(lek);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      getErrorMessage(err);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleOpenFilterPopover = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const [sortAnchorEl, setSortAnchorEl] = useState(null);

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleOpenSortPopover = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const [sortCriteria, setSortCriteria] = useState("");
  const [filterCriteria, setFilterCriteria] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryflag, setSearchQueryFlag] = useState(false);

  let fullQueryString = createQueryString(
    filterCriteria,
    searchQuery,
    sortCriteria
  );

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
    setFreeLancerDataCopy([]);
    setLek(undefined);
  };

  const handleFilterChange = (filter) => {
    setFilterCriteria((prev) => {
      if (prev.includes(filter)) {
        return prev.filter((item) => item !== filter);
      } else {
        return [...prev, filter];
      }
    });
    setFreeLancerDataCopy([]);
    setLek(undefined);
  };

  const handleSetSearchQueryFlag = useRef(
    debounce(() => {
      setFreeLancerDataCopy([]);
      setLek(undefined);
      setSearchQueryFlag((pre) => !pre);
    }, 500)
  ).current;

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    handleSetSearchQueryFlag();
  };

  useEffect(() => {
    if (lek === null && freeLancerDataCopy.length > 0) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const lekString = `lek=${encodeURIComponent(lek)}`;
          let QS = fullQueryString;
          if (lek) {
            QS = QS + "&" + lekString;
          }
          getFreelancerData(QS);
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [lek, sortCriteria, filterCriteria, searchQueryflag]);

  const getDataFn = () => {
    setFreeLancerDataCopy([]);
    getFreelancerData(fullQueryString);
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <div className="pt-4">
      <Paper
        style={{
          borderRadius: "1rem",
          boxShadow:
            "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
          border: "none",
        }}
        variant="outlined"
        className={`${styles.freelanceHeader} d-flex justify-content-between align-items-center flex-lg-row flex-column`}
      >
        <Typography
          variant="h4"
          style={{ fontSize: "2.3rem", fontWeight: "600" }}
        >
          Contractors
        </Typography>
        <div>
          <Stack direction={"row"} gap={"1rem"} pr={".5rem"}>
            <ButtonWithIcon
              disableRipple
              startIcon={<SortIcon />}
              onClick={handleOpenSortPopover}
            >
              Sort
            </ButtonWithIcon>
            <FreelancerSortPopover
              anchorEl={sortAnchorEl}
              handleClose={handleSortClose}
              handleSortChange={handleSortChange}
              check={sortCriteria}
            />

            {IS_ADMIN && (
              <ButtonWithIcon
                disableRipple
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                Add Contractor
              </ButtonWithIcon>
            )}

            <InputField
              value={searchQuery}
              variant="filled"
              placeholder="Search by name or id"
              withAdronment={true}
              onChange={(event) => {
                if (showLoader) return;
                handleSearchChange(event.target.value);
              }}
            />

            <ButtonWithIcon
              disableRipple
              startIcon={<FilterIcon />}
              onClick={handleOpenFilterPopover}
            >
              Filter
            </ButtonWithIcon>
            <FreelancerFilterPopover
              anchorEl={filterAnchorEl}
              handleClose={handleFilterClose}
              handleFilterChange={handleFilterChange}
              check={filterCriteria}
            />
          </Stack>
        </div>
      </Paper>
      <div
        className={`${styles.freelancerCardWrapper} py-4 d-flex flex-wrap justify-content-center justify-content-md-start`}
      >
        {!!freeLancerDataCopy?.length &&
          freeLancerDataCopy.map((item) => (
            <div className="px-3 my-0">
              <UserInfoCard item={item} admin={false} getData={getDataFn} />
            </div>
          ))}

        {lek !== null && (
          <div
            className="mx-3 my-0"
            ref={observerRef}
            style={{ textAlign: "center", height: "100%" }}
          >
            <div style={{ zIndex: 9999999 }}></div>
          </div>
        )}

        {!freeLancerDataCopy?.length && !showLoader && (
          <p className="txt-xlg txt-primary flex-grow-1 text-center">
            There are No Contractors!
          </p>
        )}
      </div>
      {open && (
        <AddFreelancer
          open={open}
          handleClose={handleClose}
          getData={getDataFn}
        />
      )}
    </div>
  );
};

export default FreeLancer;
