import React from "react";
import ReactDOM from "react-dom";
import "assets/styles/index.scss";
import App from "app/app";
import reportWebVitals from "reportWebVitals";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "components";
import { BrowserRouter } from "react-router-dom";
import { GlobalProvider } from "context";

const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );

          if (navigator.serviceWorker.controller) {
            console.log("Service Worker is controlling the page");
          } else {
            console.log("No Service Worker is controlling the page");
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    });
  } else {
    console.log("Service workers are not supported in this browser.");
  }
};

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
