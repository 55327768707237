import { createContext, useEffect, useReducer } from "react";
import { reducer, initialState } from "./reducer";
import AuthServices from "api/services/auth-services";
import { actions } from "context";
import { getFileNameAndType } from "utils/validator";

export const GlobalContext = createContext();

function updateVideoGroupStatus(groupName, videoId, status) {
  const videoGroup = JSON.parse(localStorage.getItem("video_group")) || [];

  videoGroup.forEach((item) => {
    if (item.group_name === groupName && item.id === videoId) {
      item.completed = status === "completed";
      item.error = status === "error";
    }
  });

  localStorage.setItem("video_group", JSON.stringify(videoGroup));
}

const downloadVideoBlob = (blob, file_name) => {
  const { fileName, fileType } = getFileNameAndType(file_name);
  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = fileName + ".mp4";
  a.type = fileType;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(downloadUrl);
};

const GlobalProvider = ({ children, ...other }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch, ...other };
  const storeHandler = (type, payload) => dispatch({ type, payload });

  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("message", async (event) => {
        if (event.data.type === "PROGRESS_UPDATE") {
          storeHandler(actions.DOWNLOAD_QUEUE, event.data);
        } else if (event.data.type === "DOWNLOAD_COMPLETE") {
          const { blob, downloadQueueData = {}, type } = event.data;
          const { group_name, id, file_name } = downloadQueueData;

          downloadVideoBlob(blob, file_name);
          updateVideoGroupStatus(group_name, id, "completed");
          storeHandler(actions.DOWNLOAD_QUEUE, { downloadQueueData, type });
        } else if (event.data.type === "DOWNLOAD_ERROR") {
          const { downloadQueueData = {} } = event.data;
          const { group_name, id } = downloadQueueData;

          updateVideoGroupStatus(group_name, id, "completed");
          storeHandler(actions.DOWNLOAD_QUEUE, event.data);
        }

        if (event.data.type === "GET_DOWNLOAD_QUEUE") {
          const downloadQueue =
            JSON.parse(localStorage.getItem("downloadQueue")) || [];
          const CD = downloadQueue[0] || {};

          try {
            const data = await AuthServices.generateDownloadURL({
              id: [CD?.id],
            });
            const { urls } = data || {};
            navigator.serviceWorker.controller.postMessage({
              type: "DOWNLOAD_QUEUE_RESPONSE",
              queue: {
                url: urls?.[0],
                ...CD,
              },
            });
          } catch (error) {
            console.log(error);
          }
        } else if (event.data.type === "REMOVE_URL_FROM_QUEUE") {
          let downloadQueue =
            JSON.parse(localStorage.getItem("downloadQueue")) || [];
          downloadQueue?.shift();
          localStorage.setItem("downloadQueue", JSON.stringify(downloadQueue));

          if (downloadQueue.length > 0) {
            navigator.serviceWorker.controller.postMessage({
              type: "CHECK_DOWNLOAD_QUEUE",
            });
          } else {
            storeHandler(actions.DOWNLOAD_QUEUE, {});
          }
        }
      });
    }

    return () => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener("message", null);
      }
    };
  }, []);

  const handleLocalStorageChange = () => {
    const downloadQueue =
      JSON.parse(localStorage.getItem("downloadQueue")) || [];

    if (downloadQueue.length > 0) {
      console.log("downloading...");

      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: "CHECK_DOWNLOAD_QUEUE",
        });
      } else {
        console.log("Service worker not controlling the page PLEASE RELOAD");
      }
    }
  };

  useEffect(() => {
    handleLocalStorageChange();

    window.addEventListener("localStorageChange", handleLocalStorageChange);
    return () => {
      window.removeEventListener(
        "localStorageChange",
        handleLocalStorageChange
      );
    };
  }, []);

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
export default GlobalProvider;
