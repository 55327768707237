import React, { useContext, useEffect, useState } from "react";
import DownloadSection from "./components/DownloadSerction/DownloadSection";
import CurrentDownload from "./components/CurrentDownload/CurrentDownload";
import AllVideo from "./components/AllVideo/AllVideo";
import { Box, Paper, Tab, Tabs, withStyles } from "@material-ui/core";
import "./styles.scss";
import { actions, GlobalContext } from "context";
import { Snackbar } from "components";
import AuthServices from "api/services/auth-services";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      display: "none",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    minWidth: "15rem",
    backgroundColor: "#e8e8e8",
    opacity: 1,
    textTransform: "none",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(22),
    "&.Mui-selected": {
      backgroundColor: theme.palette.error.main,
      color: "white",
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

function Downloads() {
  const { dispatch } = useContext(GlobalContext);
  const [allStorageVideo, setAllStorageVideo] = useState([]);
  const [tab, setTab] = useState(0);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const getAllVideo = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data } =
        await AuthServices.getStorageVideo();
      setAllStorageVideo(data);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      console.log(error);
      storeHandler(actions.SHOW_LOADER, false);
      const data = {
        message: error?.data?.message || "Something went worng!",
        class: "error",
      };
      setShowSnackBar(true);
      setSnackBarContent({ ...data });
    }
  };

  const filterByContractor = (data, filterText) => {
    if (!filterText) {
      return data;
    }
    const lowerCaseFilterText = filterText.toLowerCase();
    return data?.filter((item) =>
      item?.contractor?.toLowerCase()?.includes(lowerCaseFilterText)
    );
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  useEffect(() => {
    getAllVideo();
  }, []);

  return (
    <>
      <Box className="download-root">
        <Paper
          className="download_head"
          variant="outlined"
          style={{
            boxShadow:
              "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
            border: "none",
          }}
        >
          <StyledTabs value={tab} onChange={handleChange}>
            <StyledTab
              style={{ borderRadius: ".6rem 0 0 .6rem" }}
              label="Downloads"
              {...a11yProps(0)}
            />
            <StyledTab
              style={{ borderRadius: "0 .6rem .6rem 0" }}
              label="All Videos"
              {...a11yProps(1)}
            />
          </StyledTabs>
        </Paper>

        <TabPanel value={tab} index={0}>
          <DownloadSection />
          <CurrentDownload />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <AllVideo
            getData={filterByContractor}
            data={allStorageVideo}
            getAllVideo={getAllVideo}
          />
        </TabPanel>

        <Snackbar
          open={showSnackBar}
          message={snackbarContent?.message || ""}
          className={snackbarContent?.class || ""}
          autoHideDuration={4000}
          closeSnackBar={setShowSnackBar}
        />
      </Box>

    </>
  );
}

export default Downloads;
