import httpClient from "../http-client";
import {
  DASHBOARD,
  ADMIN,
  FREELANCER,
  FREELANCER_DETAILS,
  LOGIN,
  ADD_FREELANCER,
  ADD_ADMIN,
  GET_ADMIN_DETAIL,
  ACTIVATE_ADMIN,
  DEACTIVATE_ADMIN,
  ACTIVATE_FREELANCER,
  DEACTIVATE_FREELANCER,
  EDIT_FREELANCER,
  GET_OTP,
  VERIFY_OTP,
  RESET_PASSWORD,
  ACTIVATE_PLAYBACK,
  PLAY_BLACK_LIMIT,
  VIDEO_RENAME,
  VIDEO_DELETE,
  GET_PROFILE,
  EDIT_ADMIN,
  REFRESH_TOKEN,
  STORAGE_VIDEO,
  GENERATE_DOWNLOAD_URL,
  DELETE_VIDEO,
  ALL_USER,
  CREATE_USER,
  GET_USER_DETAIL,
  EDIT_USER,
  ACTIVATE_USER,
  DEACTIVATE_USER,
  DELETE_USER,
  DELETE_FREELANCER,
  USER_PROFILE,
  GALLERY_YEAR,
  GALLERY_MONTH,
  GALLERY_DAYS,
  GALLERY_DAY,
  VIDEO_APPROVE_REJECT,
  ADD_VIDEO_COMMENT,
  EDIT_VIDEO_COMMENT,
  DELETE_VIDEO_COMMENT,
  VIDEO_COMMENT,
  USER_TIMELINE,
  LOGOUT,
  GET_USER,
  GET_FREELANCER,
} from "../config";

const AuthServices = {
  login: (data) =>
    httpClient.post(LOGIN, data).then((response) => response.data),
  logout: () => httpClient.get(LOGOUT).then((response) => response.data),
  profile: (data) =>
    httpClient.get(USER_PROFILE, data).then((response) => response.data),
  getOtp: (data) =>
    httpClient.post(GET_OTP, data).then((response) => response.data),
  verifyOtp: (data) =>
    httpClient.post(VERIFY_OTP, data).then((response) => response.data),
  resetPassword: (data) =>
    httpClient.post(RESET_PASSWORD, data).then((response) => response.data),
  refreshToken: () =>
    httpClient.get(REFRESH_TOKEN).then((response) => response.data),

  getDashboardData: (data) =>
    httpClient
      .get(
        `${DASHBOARD}?func=dashboard&start_date=${data?.start_date}&end_date=${data?.end_date}`
      )
      .then((response) => response.data),
  getAdminData: () => httpClient.get(ADMIN).then((response) => response.data),
  addAdmin: (data) =>
    httpClient.post(ADD_ADMIN, data).then((response) => response.data),
  editAdmin: (id, data) =>
    httpClient
      .post(`${EDIT_ADMIN}/${id}`, data)
      .then((response) => response.data),
  getAdminDetail: (id) =>
    httpClient
      .get(`${GET_ADMIN_DETAIL}/${id}`)
      .then((response) => response.data),
  activateAdmin: (id) =>
    httpClient
      .post(`${ACTIVATE_ADMIN}/${id}`)
      .then((response) => response.data),
  deactivateAdmin: (id) =>
    httpClient
      .post(`${DEACTIVATE_ADMIN}/${id}`)
      .then((response) => response.data),
  activatePlayback: (data) =>
    httpClient
      .post(`${ACTIVATE_PLAYBACK}/${data}`)
      .then((response) => response.data),
  setPlaybackLimit: (data) =>
    httpClient
      .post(`${PLAY_BLACK_LIMIT}/${data}`)
      .then((response) => response.data),
  getAdminProfile: () =>
    httpClient.get(GET_PROFILE).then((response) => response.data),

  getFreelancerData: () =>
    httpClient.get(FREELANCER).then((response) => response.data),
  getFreelancer: (query) =>
    httpClient.get(`${GET_FREELANCER}&${query}`).then((response) => response.data),
  getFreelancerDetails: (id) =>
    httpClient
      .get(`${FREELANCER_DETAILS}&id=${id}`)
      .then((response) => response.data),
  addFreelancer: (data) =>
    httpClient.post(ADD_FREELANCER, data).then((response) => response.data),
  editFreelancer: (id, data) =>
    httpClient
      .post(`${EDIT_FREELANCER}`, data)
      .then((response) => response.data),
  activateFreelancer: (id) =>
    httpClient
      .post(`${ACTIVATE_FREELANCER}`, { id })
      .then((response) => response.data),
  deactivateFreelancer: (id) =>
    httpClient
      .post(`${DEACTIVATE_FREELANCER}`, { id })
      .then((response) => response.data),
  deleteFreelancer: (id) =>
    httpClient
      .post(`${DELETE_FREELANCER}`, { id })
      .then((response) => response.data),
  renameVideo: (data) =>
    httpClient.post(VIDEO_RENAME, data).then((response) => response.data),
  // deleteVideo: (data, id) =>
  //   httpClient
  //     .post(`${VIDEO_DELETE}/${id}`, data)
  //     .then((response) => response.data),
  //DOWNLOAD APIS ..............................
  getVideoURL: (url, data) =>
    httpClient.post(url, data).then((response) => response.data),
  getStorageVideo: () =>
    httpClient.get(STORAGE_VIDEO).then((response) => response.data),
  generateDownloadURL: (id) =>
    httpClient
      .post(GENERATE_DOWNLOAD_URL, id)
      .then((response) => response.data),
  deleteVideo: (videoList) =>
    httpClient.post(DELETE_VIDEO, videoList).then((response) => response.data),
  //USERS APIS ..............................
  getAllUsers: () => httpClient.get(ALL_USER).then((response) => response.data),
  getUsers: (query) => httpClient.get(`${GET_USER}&${query}`).then((response) => response.data),
  getUserTimeLine: (id) =>
    httpClient
      .get(`${USER_TIMELINE}&id=${id}`)
      .then((response) => response.data),
  createUser: (data) =>
    httpClient.post(CREATE_USER, data).then((response) => response.data),
  getUserDetail: (id) =>
    httpClient.get(GET_USER_DETAIL, id).then((response) => response.data),
  editUser: (data) =>
    httpClient.post(EDIT_USER, data).then((response) => response.data),
  activateUser: (id) =>
    httpClient.post(ACTIVATE_USER, { id }).then((response) => response.data),
  deActivateUser: (id) =>
    httpClient.post(DEACTIVATE_USER, { id }).then((response) => response.data),
  deleteUser: (id) =>
    httpClient.post(DELETE_USER, { id }).then((response) => response.data),
  //GALLERY APIS ..............................
  getGalleryYear: () =>
    httpClient.get(GALLERY_YEAR).then((response) => response.data),
  getGalleryMonth: (year) =>
    httpClient
      .get(`${GALLERY_MONTH}&year=${year}`)
      .then((response) => response.data),
  getGalleryDays: (year, month) =>
    httpClient
      .get(`${GALLERY_DAYS}&year=${year}&month=${month}`)
      .then((response) => response.data),
  getGalleryDay: (year, month, day) =>
    httpClient
      .get(`${GALLERY_DAY}&year=${year}&month=${month}&day=${day}`)
      .then((response) => response.data),
  getUserGalleryVideo: (year, month, day, user_id) =>
    httpClient
      .get(
        `${GALLERY_DAY}&year=${year}&month=${month}&day=${day}&user_id=${user_id}`
      )
      .then((response) => response.data),
  getVideoComment: (id) =>
    httpClient
      .get(`${VIDEO_COMMENT}&id=${id}`)
      .then((response) => response.data),
  changeVideoStatus: (data) =>
    httpClient
      .post(`${VIDEO_APPROVE_REJECT}`, data)
      .then((response) => response.data),
  addVideoComment: (data) =>
    httpClient
      .post(`${ADD_VIDEO_COMMENT}`, data)
      .then((response) => response.data),
  editVideoComment: (data) =>
    httpClient
      .post(`${EDIT_VIDEO_COMMENT}`, data)
      .then((response) => response.data),
  deleteVideoComment: (data) =>
    httpClient
      .post(`${DELETE_VIDEO_COMMENT}`, data)
      .then((response) => response.data),
};

export default AuthServices;
